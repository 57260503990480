import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../../environments/environment";

@Injectable()
export class IqarusApiKeyInterceptor implements HttpInterceptor {

    constructor() {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.includes(environment.iqarusApiUrl)) {
            const newRequest = request.clone(
                {headers: request.headers.set("X-API-KEY", environment.iqarusApiKey)})
            return next.handle(newRequest);
        } else {
            return next.handle(request);
        }
    }

}
