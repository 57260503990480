import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from "@angular/common/http";
import { AbaqusApiKeyInterceptor } from "@shared/interceptors/abaqus-api-key.interceptor";
import { IqarusApiKeyInterceptor } from "@shared/interceptors/iqarus-api-key.interceptor";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'csrftoken',
            headerName: 'X-CSRFToken',
        }),
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AbaqusApiKeyInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: IqarusApiKeyInterceptor, multi: true},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
